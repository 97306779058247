import * as React from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { Field } from 'formik'
import joinClasses from '/src/functions/joinClasses'
import './styles.scss'

const Error = loadable(() => import('/src/components/Form/Error'))
const Required = loadable(() => import('/src/components/Form/Required/Required'))

const Select = ({ label, name, firstItem, data, isSubmitting, isSubmitted, className, required, onChange }) => {
    const classes = ['c-select', ...(className ? [className] : [])]

    const concatenatedClasses = joinClasses(classes)

    return (
        <div className={concatenatedClasses}>
            {label && (
                <label htmlFor={name}>
                    {label}
                    {required && <Required />}
                </label>
            )}
            <div className="c-select__field" onChange={onChange}>
                <Field as={'select'} name={name} id={name} className={'form-control'} disabled={isSubmitting || isSubmitted}>
                    <option defaultValue>{firstItem}</option>

                    {data.map((item, index) => {
                        return (
                            <option key={index} value={item}>
                                {item}
                            </option>
                        )
                    })}
                </Field>
                <Error name={name} />
            </div>
        </div>
    )
}

Select.propTypes = {
    /**
     * Select label
     */
    label: PropTypes.string,
    /**
     * Select name
     */
    name: PropTypes.string.isRequired,
    /**
     * First item label
     */
    firstItem: PropTypes.string.isRequired,
    /**
     * Select data
     */
    data: PropTypes.array.isRequired,
    /**
     * Whether form is submitting
     */
    isSubmitting: PropTypes.bool.isRequired,
    /**
     * Whether form is submitted
     */
    isSubmitted: PropTypes.bool.isRequired,
    /**
     * Optional CSS classes
     */
    className: PropTypes.string,
    /**
     * Whether field is required
     */
    required: PropTypes.bool,
    /**
     * onChange function
     */
    onChange: PropTypes.func
}

Select.defaultProps = {
    name: 'month',
    firstItem: 'Please select',
    data: [],
    isSubmitting: false,
    isSubmitted: false
}

export default Select
